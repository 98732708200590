module.exports = function ($) {
    var $carousel = $(this).find('.elementor-products-carousel');
    if (elementorFrontendConfig.isEditMode) {
        $(this).find('img[data-src]').each(function() {
            $(this).attr('src', $(this).data('src'));
        });
    }
    if (!$carousel.length) {
        return;
    }

    var savedOptions = $carousel.data('slider_options'),
        swiperOptions = {
            touchEventsTarget: 'container',
            watchOverflow: true,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            lazy : {
                checkInView: true,
                loadedClass: 'loaded'
            },
            slidesPerView: savedOptions.slidesToShowMobile,
            slidesPerGroup: savedOptions.slidesToShowMobile,
            slidesPerColumn: savedOptions.itemsPerColumn,
            breakpoints: {
                768: {
                    slidesPerView: savedOptions.slidesToShowTablet,
                    slidesPerGroup: savedOptions.slidesToShowTablet,
                    slidesPerColumn: savedOptions.itemsPerColumn,
                },
                992: {
                    slidesPerView:  savedOptions.slidesToShow,
                    slidesPerGroup: savedOptions.slidesToShow,
                    slidesPerColumn: savedOptions.itemsPerColumn,
                }
            }
        };

    if(savedOptions.autoplay){
        swiperOptions.autoplay = {
            delay: 4500,
            disableOnInteraction: true,
        };
    }
    if(savedOptions.itemsPerColumn > 1){
        swiperOptions.slidesPerColumnFill = 'row';
    }
    if(savedOptions.dots){
        swiperOptions.pagination = {
            el: '.swiper-pagination',
            clickable: true,
        };
    }
    if(savedOptions.arrows){
        swiperOptions.navigation = {
            nextEl: $( this ).find('.elementor-swiper-button-next').first()[0],
            prevEl: $( this ).find('.elementor-swiper-button-prev').first()[0],
        };
    }

    var swiperInstance = new Swiper($carousel[0], swiperOptions);



};
